@font-face {
    font-family: 'RobotoBold';
    src: local("RobotoBold"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'RobotoRegular';
    src: local("RobotoRegular"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-weight: 500;
  }
