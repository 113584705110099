#contact-title-banner {
    min-height: 35vh;
    background-image: linear-gradient(rgba(235, 235, 235, 0.35), rgba(255, 255, 255, 0)), url(../assets/images/banner.png);
    background-position: 50% 30%;
    background-size: cover;
}

.contact-title-text {
    font-size: 72px;
    font-weight: 300;
    color: white;
}
.contact-info-title{
    font-size: 32px;
    margin-bottom: 25px;
    font-weight: 300;
}
.contact-form-title{
    font-size: 32px;
    margin-bottom: 25px;
    font-weight: 300;
}
.contact-info-text{
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 300;
}

.btn-submit {
    color: white;
    background-color: #02A97A;
}

@media screen and (max-width:991px) {
    #contact-info-div {
        margin-top: 25px;
        margin-bottom: 85px;
    }
}

@media screen and (max-width:543px) {

}