// fonts
$roboto-regular : 'RobotoRegular';
$roboto-bold  : 'RobotoBold';
// color theme
$color-theme-math : #008299;
$color-black : #272727;
$color-black-light : #4C4C4C;
$color-theme :  $color-theme-math !important;
$background-theme:#F5F7FA;

//colors text
.text{
    &-nabvar{
        font-family: $roboto-bold;
    }

    &-items-footer{
        font-size: 13px;
        font-family: $roboto-regular;
        color: $color-black;
    }

    &-title{
        font-size: 48px;
        font-family: $roboto-bold;
        color: $color-black;
    }
    &-content{
        color: #707070;
        font-family: $roboto-regular;
        font-size: 16px;
    }
    &-link{
        color:$color-black;
        font-family: $roboto-regular;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;
    }
}
