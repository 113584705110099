.home-header {
    min-height: 100vh;
    background-image: linear-gradient(rgba(235, 235, 235, 0.85), rgba(255, 255, 255, 0)), url(../assets/images/mountain-road.jpg);
    background-size: cover;
}
.terms-header {
    min-height: 45vh;
    background-image: linear-gradient(rgba(235, 235, 235, 1), rgba(255, 255, 255, 0)), url(../assets/images/banner2.png);
    background-position: 50% 70%;
    background-size: cover;
}

.home-overview {
    width: 100%;
    min-height: 100vh;
    margin: 0px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: RGBA(235,235,235,1);
}

.overview-title {
    margin-bottom: 50px;
}
.overview-title-text {
    font-size: 72px;
    margin-bottom: 25px;
    font-weight: 300;
}

.row {
    justify-content: space-evenly;
    width: 100%;
    height: auto;
}

.home-card {
    // flex-basis: 21%;
    margin: 0;
    max-width: 500px;
    height: auto;
    padding: 10px;
}

.card-body {
    // height: 450px;
    vertical-align: bottom;
}

.card-image-home{
    // border-radius: 25px 25px 0px 0px;
    object-fit: contain;
    width: 100%;
    height: auto;
}

.outbound-button {
    height: auto;
    width: 120px;
}
.onboard-button {
    height: auto;
    width: 85px;
}

.btn-home {
    color: white;
    background-color: rgb(14,111,135);
}

.btn-home:hover {
    color: white;
    background-color: rgb(27, 152, 175);
    transition: 200ms linear;
}

#mission-div {
    background-image: linear-gradient(to right, rgba(14,111,135, 0.85),rgba(14,111,135,0.1));
    max-height: 400px;
    // margin-top: 100px;
    
}

#mission-session {
    // margin-left: 20px;
    // margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#toll-traffic {
    height: 450px;
    position: relative;
    bottom: 155px;
    object-fit: contain;
    margin-left: 1000px;
    margin-right: -50px;
}

#mission-title {
    padding: 40px;
}
.card-text {
    font-size: 14px;
    text-align: left;
    vertical-align: bottom;

}
.card-title {
    font-size: 24px;
    font-weight: 300;
}




@media screen and (max-width:991px) and (min-width: 525px) {
    .overview-title-text {
        font-size: 48px;
        // margin-bottom: 65px;
    }
    #overview-sub {
        font-size: 15px;
    }
    .overview-title {
        margin-bottom: 20px;
    }
    .home-header {

        // background-size: cover;
        background-position: 40px 10%;
    }
}
@media screen and (max-width:525px){
    .overview-title-text {
        font-size: 36px;
    }
    #overview-sub {
        font-size: 12px;
    }
    .overview-title {
        margin-bottom: 20px;
    }
    .card {
        display: block;
    }
    .outbound-button {
        width: 90px;
        height: auto;
    }
    .onboard-button {
        width: 90px;
        height: auto;
    }

    .banner-header {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 100;
    }
    .banner-subtext {
        margin-top: 25px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

