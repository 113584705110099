.nabvar-vado{
    align-items: center;
    width: 100%;
    padding: 1.5rem 20px;
    background-color: #FAFAFA;
    .logo{
        width: 75px;
        height: auto;
    }
    .logo-text{
        width: 165px;
        height: auto;
    }
    .navbar-vado-list{
        list-style: none;
        &.button{
            display: none;
        }
       .navbar-vado-items{
        position: relative;
        a{
         text-decoration: none;
         color: black;
        }
        &.active{
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $color-theme-math;
                bottom: -10px;
                left: 0;
            }
            a{
                color: $color-theme;
            }
        }
       }
    }
}

.navbar-collapse {
  background: transparent;
}

.logo{
    width: 75px;
    height: auto;
}
.logo-text{
    width: 165px;
    height: auto;
    margin-left: 15px;
}

.navbar.scrolled {
  background: rgb(255, 255, 255) !important;
  transition: background-color 200ms linear;
}
.navbar-collapse.scrolled {
  background: rgb(255, 255, 255) !important;
  transition: background-color 200ms linear;
}

.nav-link {
  font-weight: 500;
  font-size:18px;
}

#navbtn {
  margin-right: 15px;
}


@media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  .b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
  }

  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }

  .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
  }

  .nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }