
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap";
@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/header.scss";
@import "styles/footer.scss";
@import "styles/info.scss";

.button-vado{
    background-color: $color-theme;
    color: white;
    border-radius: 3px;
    padding: 10px 23px;
    font-size: 16px;
    font-family: $roboto-bold;
    border: none;
    width: fit-content;
    margin-top: 40px;
}




.w-max{
    margin: 0 auto;
    max-width: 1280px;
}

.h-max {
    height: 600px;
}

.w-max-nav{
    margin: 0 auto;
    max-width: 1980px;
}

.background-theme{
    width: 100%;
    background-color: $background-theme;
}

.banner-header{
    font-family: $roboto-bold;
}
.map-title-text {
    font-family: $roboto-bold;
    font-size: 81px;
}

.banner-header-white{
    font-family: $roboto-bold;
}

.banner-text{
    color: black;
    position: relative;
}

.banner-text-grey{
    color: rgb(49, 46, 46);
    position: relative;
}

.banner-text-white{
    color: white;
    position: relative;
}
.banner-text-dark{
    color: rgb(36, 46, 69);
    position: relative;
}

.vado-banner{
    height: 820px;
}
.privacy-banner{
    height: 320px;
}
.vado-section-banner{
    height: 400px;
}
.vado-sub-banner{
    height: 550px;
}

.banner{
    padding: 118px 0;
    background-image: url("../src/assets/images/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    .banner-title{
        font-size: 48px;
        font-family: $roboto-bold;
        color: white;
    }
    .banner-content{
        color: white;
        font-family: $roboto-regular;
        font-size: 16px;
    }
}

    .image-parent{
        position: relative;
    }

    .team-name{
        font-size: 24px;
    }

    .team-name-desc{
        font-size: 16px;
    }

.shadow-image{
    box-shadow: 20px 0 60px 0 rgba(170,176,181,0.45);
    &.gps{
        box-shadow: 0 40px 30px 0 rgba(0,0,0,0.1);
    }
}

.lineUp {
    animation: 3s anim-lineUp ease-out infinite;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

//xxl



@media screen and (min-width: 1400px) {
    .image-vado-map{
        width: 45%;
        margin: auto;
        // margin-top: 70px !important;
    }

    .banner-text-grey-margin{
        margin-top: 400px;
    }
    .banner-text-white-margin{
        margin-top: 450px;
    }


    .bg-image{
        width: 100%;
        height: auto;
        opacity: 0.55;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 80%';
    }
    
    .bg-image-two{
        width: 100%;
        height: auto;
        opacity: 0.75;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-three{
        width: 100%;
        height: auto;
        opacity: 0.95;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-push-pin{
        width: 100%;
        height: auto;
        opacity: 0.60;
        position: absolute;
        object-fit: cover;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }

    .portrait-photo{
        width: 10%;
        margin: auto;
    }
    .portrait-photo-two{
        width: 30%;
        margin: auto;
    }
    .portrait-photo-three{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
    }
    .portrait-photo-eduardo{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-second-row{
        
        
        width: 185px;
        height: 185px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
        border: 1px solid rgb(225, 225, 225);
    }
    .portrait-photo-omar{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 10%;
    }

    .image-partners{
        width: 235px;
        height:auto;
        margin: auto;
        opacity: .85;

        // margin-top: 70px !important;
    }

    .image-car-fisrts{
        width: 85%;
    }

    .image-car-stop{
        width: 85%;
    }

    .image-car-parking{
        width: 45%;
    }

    .image-dashboard{
        width: 45%;
        height:auto;
    }
    .image-servicemap{
        width: auto;
        height: auto; 
        display: block;
        margin-left: auto;
        margin-right: auto

    }

    .image-logo{
        width: 128px;
        height: 114px;
    }

    .image-wsdot{
        width: 165px;
        height: 114px;
    }
    .indent {
        text-indent: 10px;
      }

    .sign-up{
        width: 75%;
    }

    .payment-method-photo{
        width: 37%;
        position: absolute;
        bottom: 50px;
    }

    .account-funded-photo{
        width: 37%;
        position: absolute;
        left: 225px;
        bottom: 15px;
    }
    .toll-info-photo{
        width: 42%;
        position: absolute;
        top: 25px;
    }

    .lane-closure-photo{
        width: 42%;
        position: absolute;
        left: 225px;
        bottom: 45px;
    }

    .account-summary-one-photo{
        width: 40%;
        position: absolute;
        top: 50px;
        
    }
    .ruc-zones-photo{
        width: 115%;
        position: absolute;
        object-fit: cover;
        top: 100px;
        
    }

    .account-summary-two-photo{
        width: 40%;
        position: absolute;
        left: 200px;
        top: 100px;
    }
    
}
.section-break {
    margin-top: 50px;
    margin-bottom: 15px;
}
.subsection-break {
    margin-top: 25px;
}
.terms-title {
    margin-bottom: 35px;
}
li {
    font-size: 14px;
}

//tablets

@media screen and (max-width:991px) and (min-width: 577px){
    .image-car-fisrts{
        width: 90%;
        margin: auto;
        margin-top: 70px !important;
    }
    
    .image-car-second{
        width: 60%;
        margin: auto;
        margin-top: 70px !important;
    }

    .image-vado-map{
        width: 40%;
        margin: auto;
        margin-top: 70px !important;
    }
}

@media screen and (max-width:991px) and (min-width:769px){
    .reverse-element-tablet{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 575px){
    .banner-text-grey-margin{
        margin-top: 450px;
    }

    .bg-image{
        width: 100%;
        height: auto;
        opacity: 0.55;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
    }
    
    .bg-image-two{
        width: 100%;
        height: auto;
        opacity: 0.75;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-three{
        width: 100%;
        height: auto;
        opacity: 0.95;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .image-dashboard{
        width: 45%;
        height:auto;
    }
    .image-vado-map{
        width: 65%;
        margin: auto;
        // margin-top: 70px !important;
    }

    .sign-up{
        width: 10%;
    }
    .disappear-xs{
        display: none !important;
    }
}
@media screen and (max-width: 1399px) and (min-width: 992px) {
    .image-vado-map{
        width: 45%;
        margin: auto;
        // margin-top: 70px !important;
    }

    .banner-text-grey-margin{
        margin-top: 400px;
    }


    .bg-image{
        width: 100%;
        height: auto;
        opacity: 0.55;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 80%';
    }
    
    .bg-image-two{
        width: 100%;
        height: auto;
        opacity: 0.75;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-three{
        width: 100%;
        height: auto;
        opacity: 0.95;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    

    .portrait-photo{
        width: 10%;
        margin: auto;
    }
    .portrait-photo-two{
        width: 30%;
        margin: auto;
    }
    .portrait-photo-three{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
    }
    .portrait-photo-eduardo{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-second-row{
        
        
        width: 185px;
        height: 185px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-omar{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 10%;
    }

    .image-partners{
        width: 235px;
        height:auto;
        margin: auto;
        opacity: .85;

        // margin-top: 70px !important;
    }

    .image-car-fisrts{
        width: 85%;
    }

    .image-car-stop{
        width: 85%;
    }

    .image-car-parking{
        width: 45%;
    }

    .image-dashboard{
        width: 45%;
        height:auto;
    }
    .image-servicemap{
        width: auto;
        height: auto; 
        display: block;
        margin-left: auto;
        margin-right: auto

    }

    .image-logo{
        width: 128px;
        height: 114px;
    }

    .image-wsdot{
        width: 165px;
        height: 114px;
    }

    .sign-up{
        width: 75%;
    }

    .payment-method-photo{
        width: 37%;
        position: absolute;
        bottom: 50px;
    }

    .account-funded-photo{
        width: 37%;
        position: absolute;
        left: 225px;
        bottom: 15px;
    }
    .toll-info-photo{
        width: 42%;
        position: absolute;
        top: 25px;
    }

    .lane-closure-photo{
        width: 42%;
        position: absolute;
        left: 225px;
        bottom: 45px;
    }

    .account-summary-one-photo{
        width: 40%;
        position: absolute;
        top: 50px;
        
    }

    .account-summary-two-photo{
        width: 40%;
        position: absolute;
        left: 200px;
        top: 100px;
    }
    
}
@media screen and (max-width: 991px) and (min-width: 576px) {
    .image-vado-map{
        width: 45%;
        margin: auto;
        // margin-top: 70px !important;
    }

    .banner-text-grey-margin{
        margin-top: 350px;
    }


    .bg-image{
        width: 100%;
        height: auto;
        opacity: 0.55;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 80%';
    }
    
    .bg-image-two{
        width: 100%;
        height: auto;
        opacity: 0.75;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-three{
        width: 100%;
        height: auto;
        opacity: 0.95;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    

    .portrait-photo{
        width: 10%;
        margin: auto;
    }
    .portrait-photo-two{
        width: 30%;
        margin: auto;
    }
    .portrait-photo-three{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
    }
    .portrait-photo-eduardo{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-second-row{
        
        
        width: 185px;
        height: 185px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-omar{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 10%;
    }

    .image-partners{
        width: 235px;
        height:auto;
        margin: auto;
        opacity: .85;

        // margin-top: 70px !important;
    }

    .image-car-fisrts{
        width: 85%;
    }

    .image-car-stop{
        width: 85%;
    }

    .image-car-parking{
        width: 45%;
    }

    .image-dashboard{
        width: 45%;
        height:auto;
    }
    .image-servicemap{
        width: auto;
        height: auto; 
        display: block;
        margin-left: auto;
        margin-right: auto

    }

    .image-logo{
        width: 128px;
        height: 114px;
    }

    .image-wsdot{
        width: 165px;
        height: 114px;
    }

    .sign-up{
        width: 50%;
    }

    .payment-method-photo{
        width: 20%;
        position: relative;
    }

    .account-funded-photo{
        width: 20%;
        position: relative;
    }
    .toll-info-photo{
        width: 42%;
        position: absolute;
    }

    .lane-closure-photo{
        width: 42%;
        position: absolute;

    }

    .account-summary-one-photo{
        width: 20%;
        position: relative;
        
        
    }

    .account-summary-two-photo{
        width: 20%;
        position: relative;
        
    }
    
}

//tablets

@media screen and (max-width:991px) and (min-width: 577px){
    .image-car-fisrts{
        width: 90%;
        margin: auto;
        margin-top: 70px !important;
    }
    
    
    .image-car-second{
        width: 60%;
        margin: auto;
        margin-top: 70px !important;
    }

    .image-vado-map{
        width: 40%;
        margin: auto;
        margin-top: 70px !important;
    }
    .account-summary-one-photo{
        width: 20%;
        position: relative;       
    }

    .account-summary-two-photo{
        width: 20%;
        position: relative;   
    }
    .toll-info-photo{
        width: 20%;
        position: relative;
    }

    .lane-closure-photo{
        width: 20%;
        position: relative;
    }
}

@media screen and (max-width:991px) and (min-width:769px){
    .reverse-element-tablet{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 575px){
    .banner-text-grey-margin{
        margin-top: 275px;
    }
    .image-partners{
        width: 115px;
        height:auto;
        margin: auto;
        opacity: .85;

        // margin-top: 70px !important;
    }

    .bg-image{
        width: 100%;
        height: auto;
        opacity: 0.55;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
    }
    
    .bg-image-two{
        width: 100%;
        height: auto;
        opacity: 0.75;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .bg-image-three{
        width: 100%;
        height: auto;
        opacity: 0.95;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-repeat: 'no-repeat';
        background-position: '50% 20%';
    }
    .image-dashboard{
        width: 65%;
        height:auto;
    }
    .image-vado-map{
        width: 65%;
        margin: auto;
        // margin-top: 70px !important;
    }

    .sign-up{
        width: 10%;
    }
    .disappear-xs{
        display: none !important;
    }
    .account-summary-one-photo{
        width: 20%;
        position: relative;       
    }

    .account-summary-two-photo{
        width: 20%;
        position: relative;   
    }
    .payment-method-photo{
        width: 20%;
        position: relative;
    }

    .account-funded-photo{
        width: 20%;
        position: relative;
    }
    .toll-info-photo{
        width: 20%;
        position: relative;
    }

    .lane-closure-photo{
        width: 20%;
        position: relative;
    }
    .portrait-photo{
        width: 30%;
        margin: auto;
    }
    .portrait-photo-two{
        width: 30%;
        margin: auto;
    }
    .portrait-photo-three{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
    }
    .portrait-photo-eduardo{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-second-row{
        
        
        width: 185px;
        height: 185px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 20%;
    }
    .portrait-photo-omar{
        
        
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        object-position: 100% 10%;
    }
}


//images Responsive

@media screen and (max-width:767px){
  

    .disappear{
        display: none !important;
    }
    .reverse-flex-responsive{
        flex-direction: column-reverse;
    }
    .col-12{
        padding: 0 8px;
    }
    .padding-info{
        padding: 0 20px !important;
    }

    .banner{
        .banner-title{
            font-size: 36px;
        }
    }
}


