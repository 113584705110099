* {
    box-sizing: border-box;
}

.state {fill:rgb(208, 208, 208)}

.borders {stroke:#FFFFFF; stroke-width:1}

.dccircle {display:yes}

.separator1 {stroke:#B0B0B0; stroke-width:2}

.ca, .wa, .nv, .id, .mt, .wy, .az, .nm, .tx, .nd, .sd, .mn, .ia, .ar, .wi, .il, .ms, .in, .ky, .tn, .al, .ga, .fl, .sc, .nc, .va, .oh, .wv, .pa, .md, .de, .nj, .ny, .ct, .ri,
.ma, .nh, .me {
    fill:#02A97A
}

//  path:hover, circle:hover {
//     stroke: #003A70 !important;
//     stroke-width:2px;
//     stroke-linejoin: round;
//     fill: #003A70 !important;
//     cursor: pointer;
// }


#info-box {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #ffffff;
  border: 2px solid #BF0A30;
  border-radius: 5px;
  padding: 5px;
  font-family: arial;
}
#us-map{
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

#map-div {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 100vh;
    vertical-align: top;
    overflow: hidden;
}

#coverage {
    padding-top: 105px;
    background-color: RGBA(245,245,245,1);
}

@media screen and (max-width:1399px) and (min-width: 1200px) {
    #map-div {
        padding-bottom: 80vh;
    }
}
@media screen and (max-width:1199px) and (min-width: 992px) {
    #map-div {
        padding-bottom: 70vh;
    }
}
@media screen and (max-width:991px) and (min-width: 768px) {
    #map-div {
        padding-bottom: 55vh;
    }
}
@media screen and (max-width:767px) and (min-width: 525px) {
    #map-div {
        padding-bottom: 40vh;
    }
}
@media screen and (max-width:524px){
    #map-div {
        padding-bottom: 35vh;
    }
}