
i {
    color: rgb(14,111,135);
    background: white;
}

#tolling-onboarding {
    margin-bottom: 0px;
    margin-top: 35px;
    padding: 50px;
}

.tolling-onboarding-title {
    font-size: 64px;
}

#tolling-onboarding-title-container {
    // padding-top: 50px;
    // padding-bottom: 30px;
    max-width: 600px;
    margin: auto;
    margin-bottom: 3px !important;
}   

#onboarding-div {
    width: 100%;
}

.onboarding-image {
    width: 100%;
    object-fit: contain;
    vertical-align: bottom;
}

.tolling-card {
    // box-shadow: 120px 0 160px 0 rgba(97, 99, 101, 0.45);
    min-width: 200px;
    max-width: 385px;
    height: 100%;
    margin-bottom: 35px;
    display: block;
    border-radius: 10px;
}

.onboarding-subtext {
    font-size: 14px !important;
}

.feature-card {
    background-color: rgba(14,111,135,0.85);
}
.feature-card-2 {
    background-color: rgba(27,152,175,0.85);
}
